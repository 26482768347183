import React, { useState } from "react"
import { graphql } from "gatsby"
import Layout from "../components/layout"
import SEO from "../components/seo"
import "../templates/page/homepage/homepage.scss"

export default function Home({ data }) {
  var homePageData = data.allWpPage.nodes.find(e => e.slug === "home")
  const WORDPRESS_VIDEO =
    "https://wordpress.elizaforcolorado.com/wp-content/uploads/2022/10/Copy-of-Hamrick_PublicSafety_30.mp4"
  const [startLoad1, setStartLoad1] = useState(null)
  const [canPlayThrough1, setCanPlayThrough1] = useState(null)

  return (
    <Layout className="e4c-homepage">
      <SEO title="Home" />
      <h1>Eliza&nbsp;Hamrick for Colorado&nbsp;House</h1>
      <h2>District 61</h2>
      <video
        autoPlay
        controlsList="nodownload noremoteplayback"
        onLoadStart={() => {
          setStartLoad1(new Date())
          console.log({ startLoad1 })
        }}
        onCanPlayThrough={() => {
          setCanPlayThrough1(new Date())
          console.log({ canPlayThrough1 })
        }}
        // poster="https://wordpress.elizaforcolorado.com/wp-content/uploads/2022/10/family-picture-2-scaled.jpg"
        controls
      >
        <source src={WORDPRESS_VIDEO} type="video/webm" />
        <source src={WORDPRESS_VIDEO} type="video/mp4" />
      </video>
      <div
        className="wp-html"
        dangerouslySetInnerHTML={{ __html: homePageData.content }}
      />
    </Layout>
  )
}

//highlight-start
export const pageQuery = graphql`
  query {
    allWpPage(
      filter: { slug: { nin: ["attribution"] } }
      sort: { fields: [isFrontPage, title] }
    ) {
      nodes {
        title
        slug
        menuOrder
        nodeType
        template {
          templateName
          ... on WpDefaultTemplate {
            templateName
          }
        }
        status
        content
        parentDatabaseId
        isFrontPage
      }
    }
  }
`
//highlight-end
